.container img {
  width: 80vw;
  height: auto;
  max-width: 300px;
}

.homecontainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.homecontent {
  flex-grow: 1;
  overflow: auto;
}

.homefooter {
  flex-shrink: 0;
}

.bigbutton {
  background-color: rgba(255, 193, 7, 0.15);
}

.bigbutton:hover {
  background-color: rgba(255, 193, 7, 0.25);
  border-color: rgba(255, 193, 7, 1);
}

.bigbutton:active {
  background-color: rgba(255, 193, 7, 0.5) !important;
  border-color: rgba(255, 193, 7, 1) !important;
}
